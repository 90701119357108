import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { EIconName } from '@frontend/pole-ui/lib/components/Icon'
import { createConsultingRequestBody } from '@/components/composed/ConsultingForm/utils'
import { ConsultingFormRegistrationRules } from '@/components/composed/finansingConsulting/ConsultingFormRegistrationRules'
import { Button } from '@/components/ui'
import { useAuthenticateUser } from '@/components/ui/AuthButton/hooks'
import { TextInput } from '@/components/ui/ReactHookFormFields'
import { getUser } from '@/logic/auth/reducer'
import { applyFinancingOffer } from '@/logic/financing/actions'
import type { RootState } from '@/redux/interfaces'
import { EFinancingOfferType, EFinancingProductId } from '@/types'
import { normalizePhoneNumber } from '@/utils/formatUtils'
import { isApplyingOfferRequestBody } from '@/utils/hooks/applyingFinancingOffer/typeguard'
import { useApplyingFinancingOfferForm } from '@/utils/hooks/applyingFinancingOffer/useApplyingFinancingOfferForm'
import { ELoanProductCollapsing } from '../constants'
import { messages } from './messages'
import type { IConsultingFormValues, ILoanProductConsultProps } from './types'
import './styles.scss'

const mapState = (state: RootState) => ({
  user: getUser()(state),
})

export const LoanProductConsult: FC<ILoanProductConsultProps> = props => {
  const { setIsActiveBlock, product } = props

  const { user } = useSelector(mapState)

  const { dispatch, setOptions, profile } = useApplyingFinancingOfferForm({
    productId: EFinancingProductId.MARKETPLACE,
    offerType: EFinancingOfferType.MARKETPLACE,
  })

  const initialValues: IConsultingFormValues = {
    loanAmount: '',
    purpose: '',
    phone: profile.phone,
  }

  const { control, handleSubmit, setValue } = useForm<IConsultingFormValues>({ defaultValues: initialValues })
  const { isAuthenticated, openAuthModal } = useAuthenticateUser()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setValue('phone', normalizePhoneNumber(profile.phone)), [profile.phone])

  const onSubmit = handleSubmit((values: IConsultingFormValues) => {
    if (isAuthenticated) {
      const body = createConsultingRequestBody({ values, user, productId: product.productId })

      if (isApplyingOfferRequestBody(body)) {
        dispatch(applyFinancingOffer({ body }))
      }
    } else {
      openAuthModal({
        shouldSendCodeImmediately: true,
        initialValues: { phone: normalizePhoneNumber(values.phone) },
        onCloseDialog: () => setOptions({ formValues: createConsultingRequestBody({ values, user, productId: product.productId }) }),
      })
    }
  })

  return (
    <div className="loanProductConsult">
      <form onSubmit={onSubmit}>
        <div className="loanProductConsultContent">
          <h3>{messages.title}</h3>
          <p>{messages.description}</p>

          <div className="loanProductConsultForm">
            <TextInput
              name="phone"
              type="tel"
              placeholder={messages.form.phone.placeholder}
              rules={{ required: messages.form.errors.required }}
              control={control}
              shouldBeTouchedBeforeError={false}
              isRequired
            />
          </div>

          <ConsultingFormRegistrationRules />
        </div>

        <div className="loanProductConsultActions">
          <Button
            onClick={() => setIsActiveBlock(ELoanProductCollapsing.ORDER)}
            modifiers={['outline-gray']}
            iconPosition="left"
            iconProps={{ name: EIconName.ShevronLeft }}
          >
            {messages.actions.back}
          </Button>
          <Button type="submit" modifiers={['green']}>
            {messages.actions.submit}
          </Button>
        </div>
      </form>
    </div>
  )
}
