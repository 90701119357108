import type { FC } from 'react'
import React, { memo } from 'react'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { Badge } from '@/components/composed/financing'
import { Image } from '@/components/ui'
import { useGtm } from '@/logic/metrika/financing/gtmHelpers'
import { EGtmLabel } from '@/logic/metrika/financing/types'
import { useWebpExtension } from '@/utils/hooks/image'
import { messages } from './messages'
import type { ILoanProductImageProps } from './types'
import './styles.scss'

const LoanProductImage: FC<ILoanProductImageProps> = props => {
  const { product } = props
  const { name, badges, url, gtmId } = product

  const imageExt = useWebpExtension()
  const { pushGtmProduct } = useGtm()
  const onClickLogo = () => pushGtmProduct(gtmId, EGtmLabel.LOGO)

  return (
    <div className="loanProductImage">
      <div className="loanProductImage__badges">
        {badges.map(badge => (
          <Badge
            className="text_super-small-sm"
            text={messages[badge.text as keyof typeof messages]}
            color={badge.color}
            key={badge.text}
            url={url}
          />
        ))}
      </div>

      <CommonLink url={url} onClick={onClickLogo}>
        <Image className="loanProductImage__image" src={`loanProducts/${name}.${imageExt}`} isInStatic />
      </CommonLink>
    </div>
  )
}

export default memo(LoanProductImage)
