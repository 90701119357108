import type { FC } from 'react'
import React, { memo } from 'react'
import { Image } from '@/components/ui'
import { useWebpExtension } from '@/utils/hooks/image'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import { LoanProductTooltip } from '../LoanProductTooltip'
import { messages } from './messages'
import type { ILoanProductConditionsProps } from './types'
import './styles.scss'

const LoanProductConditions: FC<ILoanProductConditionsProps> = props => {
  const { conditions, brand, tooltip } = props

  const { isMobile } = useDeviceType()

  const imageExt = useWebpExtension()

  return (
    <div className="loanProductConditions">
      {Object.entries(conditions).map(([label, value]) => (
        <div className="loanProductConditions__item" key={label}>
          <label className="text_small text_super-small-sm loanProductConditions__label">
            {messages.label[label as keyof typeof messages.label]}
          </label>

          <p className="h4">{messages.value[value as keyof typeof messages.value]}</p>
        </div>
      ))}

      {isMobile && (
        <div className="loanProductConditions__item loanProductConditions__item--withBrand">
          <Image className="loanProductConditions__brand" isInStatic src={`${brand.logo}.${imageExt}`} />
          {tooltip && <LoanProductTooltip text={tooltip} />}
        </div>
      )}
    </div>
  )
}

export default memo(LoanProductConditions)
