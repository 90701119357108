import type { FC } from 'react'
import React, { memo } from 'react'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { useGtm } from '@/logic/metrika/financing/gtmHelpers'
import { EGtmLabel } from '@/logic/metrika/financing/types'
import { messages } from './messages'
import type { ILoanProductHeaderProps } from './types'
import './styles.scss'

const LoanProductHeader: FC<ILoanProductHeaderProps> = props => {
  const { product } = props

  const { name, url, hasNoHeaderLink, gtmId } = product
  const { title, description } = messages[name as keyof typeof messages]
  const { pushGtmProduct } = useGtm()
  const onClickTitle = () => pushGtmProduct(gtmId, EGtmLabel.TITLE)

  return (
    <>
      <h3>
        {url && !hasNoHeaderLink ? (
          <CommonLink url={url} onClick={onClickTitle}>
            {title}
          </CommonLink>
        ) : (
          title
        )}
      </h3>
      <p className="text_small-md loanProductHeader__description">{description}</p>
    </>
  )
}

export default memo(LoanProductHeader)
