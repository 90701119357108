import type { FC } from 'react'
import React, { memo } from 'react'
import { EColor } from '@frontend/pole-ui/lib/common/constants'
import { FundingProductFiltersOptions } from '@/api/kubik/credit'
import { SwitchButton } from '@/components/ui'
import type { ELoanProductOption } from '@/types'
import { messages } from './messages'
import type { ILoanProductsFilterProps } from './types'
import './styles.scss'

const LoanProductsFilter: FC<ILoanProductsFilterProps> = props => {
  const { onToggle, options } = props

  return (
    <form className="loanProductsFilter">
      <h3 className="h4-md">{messages.title}</h3>
      <div className="loanProductsFilter__switchers">
        {Object.values(FundingProductFiltersOptions).map(key => (
          <SwitchButton
            value={options.includes(key)}
            name={key}
            label={messages.labels[key]}
            labelClassName="text_body"
            switcherStyle={EColor.LIGHT_GRAY}
            switcherThumbStyle={EColor.GRAY}
            onChange={() => onToggle(key as ELoanProductOption)}
            key={key}
          />
        ))}
      </div>
    </form>
  )
}

export default memo(LoanProductsFilter)
