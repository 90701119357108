import type { FC } from 'react'
import React, { memo } from 'react'
import { Faq } from '@/components/composed'
import { useGtm } from '@/logic/metrika/financing/gtmHelpers'
import { ELoanProductAnchors } from '@/types/ELoanProductAnchors'
import { messages } from './messages'

const FinancingFaq: FC = () => {
  const { pushGtmExpandFaq } = useGtm()

  const items = [
    {
      question: messages.item1.question,
      answer: (
        <>
          {messages.item1.answer.p}
          <ul>
            <li>{messages.item1.answer.li1}</li>
            <li>{messages.item1.answer.li2}</li>
            <li>{messages.item1.answer.li3}</li>
            <li>{messages.item1.answer.li4}</li>
          </ul>
        </>
      ),
    },
    {
      question: messages.item2.question,
      answer: (
        <>
          {messages.item2.answer.p}
          <ul>
            <li>{messages.item2.answer.li1}</li>
            <li>{messages.item2.answer.li2}</li>
            <li>{messages.item2.answer.li3}</li>
            <li>{messages.item2.answer.li4}</li>
            <li>{messages.item2.answer.li5}</li>
            <li>{messages.item2.answer.li6}</li>
            <li>{messages.item2.answer.li7}</li>
          </ul>
        </>
      ),
    },
    {
      question: messages.item3.question,
      answer: (
        <>
          <div>
            <a href={`#${ELoanProductAnchors.BNPL_AGROINTEGRATOR}`}>{messages.item3.answer.p1.anchor}</a>
            {messages.item3.answer.p1.text}
          </div>
          <ul>
            <li>{messages.item3.answer.li1}</li>
            <li>{messages.item3.answer.li2}</li>
            <li>{messages.item3.answer.li3}</li>
            <li>{messages.item3.answer.li4}</li>
            <li>{messages.item3.answer.li5}</li>
            <li>{messages.item3.answer.li6}</li>
          </ul>
          <a href={`#${ELoanProductAnchors.SBERPAY}`}>{messages.item3.answer.p2}</a>
          <ul>
            <li>{messages.item3.answer.li7}</li>
            <li>{messages.item3.answer.li8}</li>
          </ul>
        </>
      ),
    },
  ]

  return <Faq title={messages.title} items={items} onExpand={pushGtmExpandFaq} />
}

export default memo(FinancingFaq)
