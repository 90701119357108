import type { TMessages } from '@/types/TMessages'

export const messages = {
  label: {
    loanAmount: 'Размер кредита',
    from5MlnSecured: 'От 5 млн с залогом',
    installmentPeriod: 'Срок рассрочки',
    freePeriod: 'Беспроцентный период',
    loanPeriod: 'Срок кредитования',
    loanPeriodV2: 'Срок кредита',
    reviewPeriod: 'Срок рассмотрения',
    softLoans: 'Льготные кредиты',
    discountedRate: 'Льготная ставка',
    commercialLoans: 'Коммерческие кредиты',
    cultures: 'Культуры',
    ageOfBusiness: 'Возраст бизнеса',
    overpayment: 'Переплата',
    installmentAmount: 'Сумма рассрочки',
    fundsIssue: 'Выдача средств',
    registrationTime: 'Время оформления',
    repaymentProcedure: 'Порядок погашения',
  },
  value: {
    any: 'любой',
    upTo5MlnUnsecured: 'до 5 млн ₽ без залога',
    upTo5Mln: 'до 5 млн ₽',
    upTo10Mln: 'до 10 млн ₽',
    upTo25Mln: 'до 25 млн ₽',
    upTo50Mln: 'до 50 млн ₽',
    upTo200Mln: 'до 200 млн ₽',
    oneDay: '1 день',
    about3WorkingDays: '3 рабочих дня',
    upTo30Days: 'до 30 дней',
    upTo60Days: 'до 60 дней',
    upTo8Months: 'до 8 мес',
    upTo12Months: 'до 12 мес',
    upTo36Months: 'до 36 месяцев',
    upTo2Years: 'до 2 лет',
    upTo10Years: 'до 10 лет',
    upTo15Years: 'до 15 лет',
    from1Pct: 'от 1%',
    upTo5Pct: 'до 5%',
    upTo7Pct: 'до 7%',
    from13Pct: 'от 13% до 19,5%',
    wheat: 'пшеница',
    upTo250Mln: 'до 250 млн ₽',
    upTo1_2Mln: 'до 1,2 млн ₽',
    upTo5WorkingDays: 'до 5 раб. дней',
    from1_1Pct: 'от 1.1% до 1.3% в мес',
    fromTreeUntilSeven: '3-7 минут',
    noMonthlyPayments: 'Без ежемесячных платежей',
  },
} satisfies TMessages
