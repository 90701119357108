import type { TMessages } from '@/types/TMessages'

export const messages = {
  title: 'Запросить консультацию',
  description: 'Менеджер свяжется с вами в течение часа в рабочий день для обсуждения выгодных условий',
  form: {
    phone: {
      placeholder: 'Номер телефона',
    },
    errors: {
      required: 'Необходимо заполнить',
    },
  },
  actions: {
    back: 'Назад',
    submit: 'Получить консультацию',
  },
} satisfies TMessages
