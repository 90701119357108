import type { FC } from 'react'
import React, { memo, useState } from 'react'
import { useGtm } from '@/logic/metrika/financing/gtmHelpers'
import type { ELoanProductOption } from '@/types'
import { LoanProductsFilter, LoanProductsHeader, LoanProductsList } from './components'
import type { ILoanProductsProps } from './types'

const LoanProducts: FC<ILoanProductsProps> = ({ isLoanCalculatorShown }) => {
  const [options, setOptions] = useState<ELoanProductOption[]>([])
  const { pushGtmFilter } = useGtm()

  const onToggleOption = (key: ELoanProductOption) => {
    const turnOn = !options.includes(key)

    if (turnOn) {
      setOptions([...options, key])
    } else {
      setOptions(options.filter(filter => filter !== key))
    }
    pushGtmFilter(key, turnOn)
  }

  return (
    <>
      <LoanProductsHeader />
      <LoanProductsFilter options={options} onToggle={onToggleOption} />
      <LoanProductsList options={options} onResetFilters={() => setOptions([])} isLoanCalculatorShown={isLoanCalculatorShown} />
    </>
  )
}

export default memo(LoanProducts)
