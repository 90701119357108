import type { TMessages } from '@/types/TMessages'

export const messages = {
  title: 'Подать заявку на кредит',
  subtitle: 'Подайте заявку и с вами свяжется наш финансовый менеджер и проконсультирует по всем вопросам',
  calc: {
    heading: 'Также вы можете рассчитать стоимость кредита онлайн',
    action: 'Кредитный калькулятор',
  },
  actions: {
    sent: 'Отправить',
    requestConsult: 'Запросить консультацию',
  },
} satisfies TMessages
