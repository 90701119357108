import type { FC } from 'react'
import React, { memo, useContext } from 'react'
import { EIconName, EIconSize, Icon } from '@frontend/pole-ui/lib/components/Icon'
import classNames from 'classnames'
import { Tabs } from '@/components/ui'
import { TabContent } from '@/components/ui/Tabs/components'
import { useGtm } from '@/logic/metrika/financing/gtmHelpers'
import { EGtmLabel, EGtmProductName } from '@/logic/metrika/financing/types'
import { FINANCING_CATEGORIES, ProductsListContext } from '@/pages/Financing/constants'
import { scrollTo } from '@/utils/helpers'
import { LeasingLabelComponent, LoanLabelComponent } from '../FinancingProducts/constants'
import { messages } from './messages'
import type { IFinancingStickyBannerProps } from './types'
import './styles.scss'

const FinancingStickyBanner: FC<IFinancingStickyBannerProps> = ({ activeTab }) => {
  const { pushGtmProduct } = useGtm()
  const productsListContext = useContext(ProductsListContext)

  const onClick = () => {
    pushGtmProduct(EGtmProductName.MARKETPLACE, EGtmLabel.ACTION)
    scrollTo('.financingProducts__title')
  }

  const shouldShowBanner =
    productsListContext &&
    productsListContext.productsList.isBlockIntersected === 'true' &&
    productsListContext.productTabs.isBlockIntersected === 'false'

  return (
    <div
      className={classNames('financingStickyBanner', {
        'financingStickyBanner--show': shouldShowBanner,
      })}
    >
      <div className="financingStickyBanner__container">
        <div className="financingStickyBanner__info">
          <Icon name={EIconName.Work} size={EIconSize.L} className="financingStickyBanner__icon" />
          <span>{messages.text}</span>
        </div>

        <Tabs
          tabListClassName="financingStickyBanner__tabList"
          tabItemClassName="financingStickyBanner__tabItem"
          onTabClick={onClick}
          overrideActiveTab={activeTab}
          defaultActiveTab={FINANCING_CATEGORIES.indexOf(activeTab)}
        >
          <TabContent title="loans" tabId="loans" LabelComponent={LoanLabelComponent} />
          <TabContent title="leasing" tabId="leasing" LabelComponent={LeasingLabelComponent} />
        </Tabs>
      </div>
    </div>
  )
}

export default memo(FinancingStickyBanner)
