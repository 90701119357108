import type { FC } from 'react'
import React, { useContext } from 'react'
import Tabs from '@/components/ui/Tabs'
import { TabContent } from '@/components/ui/Tabs/components'
import { FINANCING_CATEGORIES, ProductsListContext } from '@/pages/Financing/constants'
import { LeasingOffers, LoanProducts } from './components'
import { LeasingLabelComponent, LoanLabelComponent } from './constants'
import { messages } from './messages'
import type { IFinancingProductsProps } from './types'
import './styles.scss'

export const FinancingProducts: FC<IFinancingProductsProps> = ({ activeTab, isLoanCalculatorShown }) => {
  const productsListContext = useContext(ProductsListContext)

  return (
    <>
      <div className="financingProducts__title">
        <h2>{messages.title}</h2>
      </div>
      <div className="financingProducts" ref={productsListContext?.productsList.blockRef}>
        <Tabs
          tabListRef={productsListContext?.productTabs.blockRef}
          tabListClassName="financingProducts__tabList"
          tabItemClassName="financingProducts__tabItem"
          overrideActiveTab={activeTab}
          defaultActiveTab={FINANCING_CATEGORIES.indexOf(activeTab)}
        >
          <TabContent title="loans" tabId="loans" LabelComponent={LoanLabelComponent}>
            <LoanProducts isLoanCalculatorShown={isLoanCalculatorShown} />
          </TabContent>
          <TabContent title="leasing" tabId="leasing" LabelComponent={LeasingLabelComponent}>
            <LeasingOffers />
          </TabContent>
        </Tabs>
      </div>
    </>
  )
}
